import { template as template_aaf89971d6bf415d8ac2523cb8391e25 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aaf89971d6bf415d8ac2523cb8391e25(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
