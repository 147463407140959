import { template as template_86d15c99d11a44f685004e475143b18a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_86d15c99d11a44f685004e475143b18a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
